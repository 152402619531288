

    html,
    body,
    #app {
        height: 100%;
        min-height: 100%;
    }

    body {
        #app {
            .app-body {
                min-height: 100%;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .page-content {
                    flex: 1 0 auto;
                    position: relative;

                    .breadcrumb + .page-content-data {
                        margin-top: 0 !important; // Breadcrumb has own margins
                    }

                    .page-content-data {
                        margin-top: 1rem;

                        &.page-message:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
